<template>
	<div class="invite-download-page">
		<van-image
			fit="contain"
			:src="resourceURL + 'images/invite/logo.png'"
			class="logo"
		/>

		<van-row class="buttons">
			<van-col span="24" style="margin-bottom:35px;color:#FFFFFF;text-align:center;line-height:200%;">
				您已注册成功！<br />可以点击下方按钮去下载APP
			</van-col>
			<van-col span="24">
				<van-button type="default" block round color="#FE6D01" plain size="large" @click="nextStep">去下载</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		name: 'invite_download',
		data() {
			return {
				formItem: {
					code: "",
					name: "",
					idcode: "",
					email: "",
				},

				token: "",

				resourceURL: this.$axios.defaults.resourceURL,
				
			}
		},
		
		methods: {
			
			nextStep() {
				
				this.$router.replace('/invite/download')
			
			}
		},
	}
</script>